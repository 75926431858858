// @ts-check
import React from 'react'
import Layout from '../components/layout'

const IndexPage = () => {
  return (
    <Layout>
      <div className="content">
        <h1>AngelEye Health Policies {'&'} Procedures</h1>
        <p>Please select a page from the navigation menu.</p>
      </div>
    </Layout>
  )
}

export default IndexPage
